import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Container, Grid, Paper, Typography, List, ListItem, ListItemText, TextField, Divider, FormControl, InputLabel, Select, ListItemSecondaryAction, Checkbox, Box, AppBar, Tabs, Tab } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

import AdminSingleUserTracking from './AdminSingleUserTracking'
import Loader from '../../components/Loader'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

export default function AdminSingleUser (props) {

    const id = props.match.params.id
    const [user,setuser] = useState({})
    const [tasks,settasks] = useState([])
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        firebase.getDocument('users',id).onSnapshot(snap => {
            setuser(snap.data())
        })
        firebase.getCollection('tasks').where('userId','==',id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            settasks(data)
        })
    },[id])

    const incomplete = _.filter(tasks, o => {
        return o.complete ===  false
    })
    const complete = _.filter(tasks, o => {
        return o.complete === true
    })

    return user ? (
        <div className="admin-single-user">
            <AppBar position="relative">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Seguimiento" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Container>
                    <Grid style={{ marginTop: '.5rem' }} container spacing={3}>
                        <Grid component={Paper} item xs={12} md={6}>
                            <Typography variant="h4" component="h1">Información General</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary="Nombre"
                                        secondary={user.name}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Email"
                                        secondary={user.email}
                                    />
                                </ListItem>
                                {
                                    user.phone && <ListItem>
                                        <ListItemText
                                            primary="Teléfono"
                                            secondary={user.phone}
                                        />
                                    </ListItem>
                                }
                                <ListItem>
                                    <TextField
                                        label="Bandeja de Entrada"
                                        fullWidth
                                        variant="outlined"
                                        value={user.corpEmail ? user.corpEmail : ''}
                                        onChange={e => firebase.updateDoc('users',user.id,'corpEmail',e.target.value)}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: '4.5rem' }} />
                    <Paper style={{ padding: '2.5rem', marginTop: '2.5rem' }}>
                        <Typography variant="h4" component="h2">Cuentanos un poco más sobre ti</Typography>
                        <Grid style={{ marginTop: '2.5rem' }} container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="age-native-simple">Ocupación</InputLabel>
                                    <Select
                                        native
                                        value={user.occupation ? user.occupation : ''}
                                        inputProps={{
                                            id: 'age-native-simple',
                                        }}
                                    >
                                        <option value="" />
                                        <option value="Empresario">Empresario</option>
                                        <option value="Freelancer">Freelancer (Trabajo por mi cuenta)</option>
                                        <option value="Trabajo para una empresa">Trabajo para una empresa</option>
                                        <option value="Estudiante">Estudiante</option>
                                        <option value="Otro">Otro</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {user.occupation && <TextField
                                    label={user.occupation === 'Empresario' ? '¿De que es tu empresa?' : user.occupation === 'Freelancer' ? '¿A qué te dedicas?' : user.occupation === 'Trabajo para una empresa' ? '¿De qués es la empresa en la que trabajas y cuál es tu puesto?' : user.occupation === 'Estudiante' ?'¿Qué estudias?' : '¿A qué te dedicas?'}
                                    variant="outlined"
                                    fullWidth
                                    value={user.aboutOccupation ? user.aboutOccupation : ''}
                                />}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="¿Cúal es tu código postal?"
                                    fullWidth
                                    variant="outlined"
                                    value={user.zipCode ? user.zipCode : ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="¿Cúal es el código postal de tu trabajo?"
                                    fullWidth
                                    variant="outlined"
                                    value={user.jobZipCode ? user.jobZipCode : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" variant="h5" component="h3">¿Cuáles son tus principales temas de interés?</Typography>
                                <List style={{ marginTop: '1rem' }} component={Grid} container spacing={3}>
                                    {user.pleasures ? user.pleasures.map((item,index) => <ListItem key={index} component={Grid} item xs={12} md={6} >
                                        <ListItemText
                                            primary={item.label}
                                        />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                checked={item.status}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>) : ''}
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid style={{ marginTop: '2rem' }} container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography style={{ marginBottom: '2rem', marginTop: '2rem' }} align="center" variant="h5" component="h2">Pendientes</Typography>
                            {
                                incomplete.map(item => <Box position="relative" key={item.id} display="flex" flexDirection="column" marginBottom="2rem" component={Paper} width="100%" padding="2.5rem">
                                    <Checkbox onClick={() => firebase.updateDoc('tasks',item.id,'complete',!item.complete)} style={{ position: 'absolute', top: 5, right: 5 }} checked={item.complete} />
                                    <Typography variant="body1">{item.description}</Typography>
                                    <Typography variant="caption" align="right">{moment(item.timestamp).format('DD-MM-YYYY')}</Typography>
                                </Box>)
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography style={{ marginBottom: '2rem', marginTop: '2rem' }} align="center" variant="h5" component="h2">Atendidos</Typography>
                            {
                                complete.map(item => <Box position="relative" key={item.id} display="flex" flexDirection="column" marginBottom="2rem" component={Paper} width="100%" padding="2.5rem">
                                    <Checkbox onClick={() => firebase.updateDoc('tasks',item.id,'complete',!item.complete)} style={{ position: 'absolute', top: 5, right: 5 }} checked={item.complete} />
                                    <Typography variant="body1">{item.description}</Typography>
                                    <Typography variant="caption" align="right">{moment(item.timestamp).format('DD-MM-YYYY')}</Typography>
                                </Box>)
                            }
                        </Grid>
                    </Grid>
                </Container>
            </TabPanel>
            <TabPanel value={value} index={1}><AdminSingleUserTracking id={id}/></TabPanel>
        </div>
    ) : <Loader />
}