import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Switch, Route, Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Button, AppBar, Toolbar, Drawer, IconButton, Typography, Hidden } from '@material-ui/core'
import { ThemeProvider, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ChatIcon from '@material-ui/icons/Chat';
import ListIcon from '@material-ui/icons/List';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AppsIcon from '@material-ui/icons/Apps';

import appTheme from '../utils/theme'
import Profile from '../pages/app/Profile'
import Loader from './Loader';
import Comunication from '../pages/app/Comunication';
import Tracking from '../pages/app/Tracking';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

const MenuButton = withStyles({
    root: {
        borderRadius: 100,
        border: '1px solid #fff',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .1)',
        },
        paddingLeft: '1rem',
        paddingRight: '1rem'
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

export default function App (props) {

    const loc = props.location.pathname.split('/')[2]
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [user,setUser] = useState(undefined)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (!user) {
                props.history.push('/iniciar-sesion')
            } else {
                firebase.getDocument('users',user.uid).onSnapshot(snap => {
                    setUser(snap.data())
                })
            }
        })
    },[props.history])

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem selected={loc === '' || loc === undefined ? true : false} component={Link} to="/app" button>
                    <ListItemIcon><PersonIcon/></ListItemIcon>
                    <ListItemText primary="Perfil" />
                </ListItem>
                <ListItem selected={loc === 'comunicacion' ? true : false} component={Link} to="/app/comunicacion" button>
                    <ListItemIcon><ChatIcon/></ListItemIcon>
                    <ListItemText primary="Comunicación" />
                </ListItem>
                <ListItem selected={loc === 'seguimiento' ? true : false} component={Link} to="/app/seguimiento" button>
                    <ListItemIcon><ListIcon/></ListItemIcon>
                    <ListItemText primary="Seguimiento" />
                </ListItem>
            </List>
            <Divider />
            {user ? user.rol === 'admin' ? <List>
                <ListItem component={Link} to="/admin" button>
                    <ListItemIcon><AppsIcon/></ListItemIcon>
                    <ListItemText primary="Panel de Administración" />
                </ListItem>
            </List> : '' : ''}
            <List>
                <ListItem component={Link} to="/" button>
                    <ListItemIcon><ArrowBackIosIcon/></ListItemIcon>
                    <ListItemText primary="Página Principal" />
                </ListItem>
            </List>
        </div>
    );

    return user ? (
        <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ color: '#fff', fontWeight: '700' }} variant="h6" noWrap>
                        Perfil de Usuario
                    </Typography>
                    <MenuButton onClick={handleLogout} color="default" style={{ marginLeft: 'auto' }} variant="outlined">Cerrar Sesión</MenuButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route path="/app" render={props => <Profile {...props} user={user} />} exact={true} />
                    <Route path="/app/comunicacion" render={props => <Comunication {...props} user={user} />} exact={true} />
                    <Route path="/app/seguimiento" render={props => <Tracking {...props} user={user} />} exact={true} />
                </Switch>
            </main>
        </ThemeProvider>
    ) : <Loader />

    async function handleLogout () {
        try {
            firebase.logout().then(() => {
                props.history.push('/')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}