import React, { useEffect, useState } from 'react'
import firebase from '../../utils/firebase'

export default function Contact () {

    const [name,setname] = useState('')
    const [phone,setphone] = useState('')
    const [email,setemail] = useState('')
    const [message,setmessage] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="contact">
            <form onSubmit={e => e.preventDefault() && false}>
                <h1>¡Contáctanos! ¿Tienes preguntas? Un asesor te brindará la información necesaria.</h1>
                <div className="input-container">
                    <label>Nombre</label>
                    <input placeholder="Nombre" value={name} onChange={e => setname(e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <label>Teléfono</label>
                    <input placeholder="Teléfono" value={phone} onChange={e => setphone(e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <label>Email</label>
                    <input placeholder="Email" value={email} onChange={e => setemail(e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <label>Mensaje</label>
                    <textarea placeholder="Mensaje" value={message} onChange={e => setmessage(e.target.value)} rows="5" />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Enviar Mensaje</button>
                </div>
            </form>
        </div>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                const item = {
                    name,
                    phone,
                    email,
                    message
                }
                firebase.sendMessage(item).then(() => {
                    setname('')
                    setphone('')
                    setemail('')
                    setmessage('')
                    alert('Mensaje enviado, un asesor se pondrá en contacto contigo')
                })
            } else {
                alert('Escribe tu nombre, email y mensaje')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}