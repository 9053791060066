import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import Checkbox from '@material-ui/core/Checkbox'
import moment from 'moment'

export default function AdminSingleUserTracking (props) {

    const {id} = props

    const [title,settitle] = useState('')
    const [description,setdescription] = useState('')
    const [file,setfile] = useState('')
    const [tracks,settracks] = useState([])

    useEffect(() => {
        firebase.getCollection('tracks').where('userId','==',id).orderBy('timestamp').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            settracks(data)
        })
    },[id])

    return (
        <div className="admin-single-user-tracking">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Título</label>
                    <input value={title} onChange={e => settitle(e.target.value)} placeholder="Título" type="text"/>
                </div>
                <div className="input-container">
                    <label>Descripción</label>
                    <textarea value={description} onChange={e => setdescription(e.target.value)} placeholder="Descripción" rows="4"/>
                </div>
                <div className="input-container">
                    <label>Archivo</label>
                    <input onChange={e => setfile(e.target.files[0])} placeholder="Archivo" type="file"/>
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <div className="content">
                <table>
                    <thead>
                        <tr>
                            <th>Activo</th>
                            <th>Fecha</th>
                            <th>Título</th>
                            <th>Descripción</th>
                            <th>Archivo</th>
                        </tr>
                    </thead>
                    <tbody>{tracks.map(item => <tr key={item.id}>
                        <td><Checkbox
                            checked={item.isActive}
                            onClick={() => firebase.updateDoc('tracks',item.id,'isActive',!item.isActive)}
                        /> </td>
                        <td>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        <td>{item.file ? <a href={item.file} rel="noopener noreferrer" target="_blank">Descargar</a> : ''}</td>
                    </tr>)}</tbody>
                </table>
            </div>
        </div>
    )

    async function handleSubmit () {
        try {
            if (title && description) {
                const item = {
                    title,
                    description,
                    userId: id,
                    isActive: false
                }
                firebase.simpleAdd(item,'tracks').then(itemId => {
                    if (file) {
                        firebase.addFileDoc('tracks',itemId,'file',file).then(() => {
                            alert('Elemento agregado')
                            settitle('')
                            setdescription('')
                            setfile('')
                        })
                    } else {
                        alert('Elemento agregado')
                        settitle('')
                        setdescription('')
                        setfile('')
                    }
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}