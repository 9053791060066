import React, { useEffect } from 'react'

export default function Priv () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="terms">
            <div className="terms__container">
                <h1>Aviso de Privacidad</h1>
                <p>STOQ Crowdfunding sas de cv, en lo sucesivo (“Asistencia.Vip”) es una plataforma en de asistencia en línea que tiene por objetivo brindar un servicio de asesoría y ser un intermediario entre proveedores y cliente. </p>
                <p>Nuestras políticas atienden a proteger, a nuestros socios comerciales, a Asistencia.Vip, y a todos los usuarios de nuestra plataforma por lo que se entiende que todos los actos realizados por este medio, son conforme al principio de la buena fe. Al utilizar la plataforma de Asistencia.Vip,  se aceptan todas las condiciones que se enunciarán a continuación y se renuncia expresamente a ejercer cualquier acción que pueda atentar contra el patrimonio de nuestros socios comerciales y de Asistencia.Vip. </p>
                <h2>Uso</h2>
                <p>¡Bienvenido! Asistencia.Vip, te ofrece una amplia gama de servicios administrativos, asistencia, asesoría, intermediación, entre otros donde participan diversos proveedores por lo que en ocasiones estaremos sujetos a la aplicación de condiciones adicionales en referencia a los terceros enunciados. Cada vez que se utilice la plataforma Asistencia.Vip, el usuario estará sujeto a los lineamientos, términos y condiciones particulares aplicables a dicho Servicio. </p>
                <h2>Comunicaciones Electrónicas.</h2>
                <p>Todos los documentos, avisos, mensajes, y otras notificaciones y comunicaciones que le enviemos por medios electrónicos satisfacen cualquier requisito legal de forma escrita y cuentan con plenos efectos de validez.</p>
                <h2>Derechos De Autor y Propiedad Intelectual.</h2>
                <p>Todo contenido alojado o puesto a disposición en Asistencia.Vip es propiedad de Asistencia.Vip. o de sus proveedores y está protegido por las leyes internacionales y de los Estados Unidos Mexicanos de propiedad intelectual.</p>
                <p>Asistencia.Vip respeta la propiedad intelectual de terceros por lo que si cualquier persona considera que su trabajo ha sido reproducido de manera tal que constituya una infracción a derechos de propiedad intelectual, le rogamos que levante un reporte a la dirección de hola@asistencia.vip para que se suspenda la publicación de tal objeto en lo que el equipo de Asistencia.Vip realiza una investigación con los proveedores en cuestión hasta que alguna de las partes logre acreditar el derecho que le confiere materia de la controversia. </p>
                <p>En cuanto a los proveedores, la única relación que tiene Asistencia.Vip y el usuario es de intermediario con el proveedor que brinda el servicio.</p>
                <h2>Precios</h2>
                <p>Excepto mención en contrario, todos los precios mostrados en Asistencia.Vip incluyen impuestos.</p>
                <h2>Controversias</h2>
                <p>Cualquier queja, reclamo o controversia deberá ser primeramente planteada a Asistencia.Vip para que Asistencia.Vip. valore siguiendo los principios de colaboración y buena fe la posible reparación del daño y de no llegar a ningún acuerdo las partes podrán resolverlo mediante los tribunales de la Ciudad de México o bien priorizando la resolución por medio de métodos alternos de solución de controversia.</p>
                <h2>Pago</h2>
                <p>Tus pagos son realizados de forma segura con encriptación de 256 bits, mediante STRIPE como pasarela de pagos.</p>
                <h2>Datos de contacto</h2>
                <p><b>Correo Electrónico: </b> hola@asistencia.vip</p>
                <p><b>Dirección: </b> Alejandro Dumas 71, Polanco IV Secc. Miguel Hidalgo. CDMX. CP 11550. </p>
            </div>
        </div>
    )
}