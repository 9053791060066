import React, { useState,useEffect } from 'react'
import firebase from '../../utils/firebase'

import ServiceItem from '../../components/ServiceItem'

export default function Services () {

    const [services,setServices] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getCollection('services').orderBy('name').where('isActive','==','true').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setServices(data)
        })
    },[])

    return (
        <div className="services">
            <div className="services__container">
                <h1>Conoce todos nuestros servicios incluidos</h1>
                <ul>
                    {services.map(item => <ServiceItem key={item.id} data={item} />)}
                </ul>
            </div>
        </div>
    )
}