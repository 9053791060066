import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Container, Grid, Paper, Typography, List, ListItem, ListItemText, TextField, Button, Divider, FormControl, InputLabel, Select, ListItemSecondaryAction, Checkbox } from '@material-ui/core'

export default function Profile ({ user }) {

    const [phone,setphone] = useState('')
    const [occupation,setoccupation] = useState('')
    const [aboutOccupation,setaboutOccupation] = useState('')
    const [zipCode,setzipCode] = useState('')
    const [jobZipCode,setjobZipCode] = useState('')
    const [pleasures,setpleasures] = useState([
        { label: 'Arte', status: false },
        { label: 'Cultura', status: false },
        { label: 'Entretenimiento', status: false },
        { label: 'Ciencia', status: false },
        { label: 'Tecnología', status: false },
        { label: 'Clima', status: false },
        { label: 'Deportes', status: false },
        { label: 'Ecología', status: false },
        { label: 'Economía', status: false },
        { label: 'Negocios', status: false },
        { label: 'Política', status: false },
        { label: 'Salud', status: false },
        { label: 'Sociedad', status: false },
    ])

    const handleToggle = (index,val) => {
        var newList = [...pleasures]
        newList[index].status = val
        setpleasures(newList)
    }
    
    useEffect(() => {
        if (user.occupation || user.aboutOccupation || user.zipCode || user.jobZipCode || user.pleasures) {
            setoccupation(user.occupation)
            setaboutOccupation(user.aboutOccupation)
            setzipCode(user.zipCode)
            setjobZipCode(user.jobZipCode)
            setpleasures(user.pleasures)
        }
    },[user.occupation, user.aboutOccupation, user.zipCode, user.jobZipCode, user.pleasures])

    return (
        <Container>
            <Grid style={{ marginTop: '.5rem' }} container spacing={3}>
                <Grid component={Paper} item xs={12} md={6}>
                    <Typography variant="h4" component="h1">Información General</Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Nombre"
                                secondary={user.name}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Email"
                                secondary={user.email}
                            />
                        </ListItem>
                        {
                            user.phone && <ListItem>
                                <ListItemText
                                    primary="Teléfono"
                                    secondary={user.phone}
                                />
                            </ListItem>
                        }
                        <ListItem>
                            <ListItemText
                                primary="Tu bandeja de entrada"
                                secondary={user.corpEmail}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={6}>
                    {!user.phone && <Paper style={{ padding: '2.5rem' }}>
                        <TextField
                            label="Teléfono"
                            value={phone}
                            onChange={e => setphone(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                        <Button onClick={() => firebase.updateDoc('users',user.id,'phone',phone).then(() => alert('Teléfono establecido'))} size="large" style={{ marginTop: '1rem' }} fullWidth variant="contained" color="primary">Establecer Teléfono</Button>
                    </Paper>}
                    
                </Grid>
            </Grid>
            <Divider style={{ marginTop: '4.5rem' }} />
            <Paper style={{ padding: '2.5rem', marginTop: '2.5rem' }}>
                <Typography variant="h4" component="h2">Cuentanos un poco más sobre ti</Typography>
                <Grid style={{ marginTop: '2.5rem' }} container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="age-native-simple">Ocupación</InputLabel>
                            <Select
                                native
                                value={occupation}
                                onChange={e => setoccupation(e.target.value)}
                                inputProps={{
                                    id: 'age-native-simple',
                                }}
                            >
                                <option value="" />
                                <option value="Empresario">Empresario</option>
                                <option value="Freelancer">Freelancer (Trabajo por mi cuenta)</option>
                                <option value="Trabajo para una empresa">Trabajo para una empresa</option>
                                <option value="Estudiante">Estudiante</option>
                                <option value="Otro">Otro</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {occupation && <TextField
                            label={occupation === 'Empresario' ? '¿De que es tu empresa?' : occupation === 'Freelancer' ? '¿A qué te dedicas?' : occupation === 'Trabajo para una empresa' ? '¿De qués es la empresa en la que trabajas y cuál es tu puesto?' : occupation === 'Estudiante' ?'¿Qué estudias?' : '¿A qué te dedicas?'}
                            variant="outlined"
                            fullWidth
                            value={aboutOccupation}
                            onChange={e => setaboutOccupation(e.target.value)}
                        />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="¿Cúal es tu código postal?"
                            fullWidth
                            variant="outlined"
                            value={zipCode}
                            onChange={e => setzipCode(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="¿Cúal es el código postal de tu trabajo?"
                            fullWidth
                            variant="outlined"
                            value={jobZipCode}
                            onChange={e => setjobZipCode(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5" component="h3">¿Cuáles son tus principales temas de interés?</Typography>
                        <List style={{ marginTop: '1rem' }} component={Grid} container spacing={3}>
                            {pleasures.map((item,index) => <ListItem key={index} component={Grid} item xs={12} md={6} >
                                <ListItemText
                                    primary={item.label}
                                />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onClick={() => handleToggle(index,!item.status)}
                                        checked={item.status}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>)}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button onClick={handleSubmit} fullWidth variant="contained" color="primary">Guardar Información</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )

    async function handleSubmit () {
        try {
            if (occupation && aboutOccupation && zipCode && jobZipCode && pleasures) {
                firebase.getDocument('users',user.id).update({
                    occupation,
                    aboutOccupation,
                    zipCode,
                    jobZipCode,
                    pleasures,
                }).then(() => {
                    alert('Información guardada')
                })
            } else {
                alert('Responde todas las preguntas para guardar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}