import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Container, Box, Paper, FormControl, InputLabel, Input, InputAdornment, Grid, Typography, Card, CardContent, CardActions, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'

import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function Tracking ({ user }) {

    const classes = useStyles();
    const [tracks,settracks] = useState([])
    const [search,setsearch] = useState('')

    useEffect(() => {
        firebase.getCollection('tracks').where('userId','==',user.id).where('isActive','==',true).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            settracks(data)
        })
    },[user.id])

    const filter = search ? _.filter(tracks, item => {
        return _.lowerCase(item.title + ' ' + item.description).match(_.lowerCase(search))
    }) : tracks
    
    return (
        <Container>
            <Box component={Paper} padding="2.5rem">
                <FormControl fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">Buscador</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        value={search}
                        onChange={e => setsearch(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box padding="2.5rem">
                <Grid container spacing={3}>
                    {
                        filter.map(item => <Card style={{ margin: '1rem 0' }} key={item.id} component={Grid} item xs={12}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary">
                                    {item.title}
                                </Typography>
                                <Typography variant="caption">{item.description}</Typography>
                            </CardContent>
                            {item.file ? <CardActions>
                                <Button color="primary" variant="outlined" component="a" href={item.file} target="_blank" rel="noopener noreferrer" size="medium">Descargar</Button>
                            </CardActions> : ''}
                        </Card>)
                    }
                </Grid>
            </Box>
        </Container>
    )
}