import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'
import numeral from 'numeral'

const Item = ({ data }) => {

    const [pending,setpending] = useState('')

    useEffect(() => {
        firebase.getCollection('tasks').where('userId','==',data.id).where('complete','==',false).get().then(snap => {
            setpending(snap.size)
        })
    },[data.id])

    return (
        <tr>
            <td>{moment(data.timestamp).format('DD-MM-YYYY')}</td>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.phone}</td>
            <td style={{ textAlign: 'center' }}>{numeral(pending).format('0')}</td>
            <td style={{ textAlign: 'center' }}><Link to={`/admin/usuarios/${data.id}`}><i className="material-icons">arrow_forward_ios</i></Link></td>
        </tr>
    )
}

export default function AdminUsers () {

    const [users,setusers] = useState([])

    useEffect(() => {
        firebase.getCollection('users').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setusers(data)
        })
    },[])

    return (
        <div className="admin-users">
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Pendientes</th>
                        <th>Ver</th>
                    </tr>
                </thead>
                <tbody>{
                    users.map(item => <Item key={item.id} data={item} />)
                }</tbody>
            </table>
        </div>
    )
}