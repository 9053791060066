import React, { useState } from 'react'
import firebase from '../utils/firebase'
import { useEffect } from 'react'

export default function Login (props) {

    const [email,setemail] = useState('')
    const [password,setpassword] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.isInitialized().then(user => {
            if (user) {
                props.history.push('/app')
            }
        })
    },[props.history])

    return (
        <div className="login">
            <form onSubmit={e => e.preventDefault() && false}>
                <h1>Iniciar Sesión</h1>
                <div className="input-container">
                    <label>Email</label>
                    <input value={email} onChange={e => setemail(e.target.value)} type="email" />
                </div>
                <div className="input-container">
                    <label>Contraseña</label>
                    <input value={password} onChange={e => setpassword(e.target.value)} type="password" />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Iniciar Sesión</button>
                </div>
            </form>
        </div>
    )

    async function handleSubmit () {
        try {
            if (email && password) {
                firebase.login(email,password).then(() => {
                    props.history.push('/app')
                }).catch(error => {
                    alert(error.message)
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}