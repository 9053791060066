import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'

export default function Process () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="process">
            <div className="process__container">
                <h1>¿Cómo funciona?</h1>
                <ul>
                    <li>
                        <picture>
                            <img src="/images/a2.png" alt="Realiza tu registro"/>
                        </picture>
                        <div className="content">
                            <h3>Realiza tu registro</h3>
                            <p>Únicamente necesitas tu correo electrónico y una contraseña para comenzar, recibe 10 días de prueba sin costo y sin seleccionar método de pago.</p>
                            <Link to="/registro">Registrarme</Link>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <img src="/images/a3.png" alt="Completa el formulario inicial"/>
                        </picture>
                        <div className="content">
                            <h3>Completa el formulario inicial</h3>
                            <p>Completa tus datos iniciales para comenzar a saber un poco más de ti, nos encanta conocerte, en base a tus gustos y hábitos te enviaremos preguntas para saber más de ti y brindarte un mejor servicio.</p>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <img src="/images/a4.png" alt="¿Ya estás registrado?"/>
                        </picture>
                        <div className="content">
                            <h3>¿Ya estás registrado?</h3>
                            <p>Es hora de comenzar, recibirás un correo electrónico con los datos de contacto de tu servicio de asesoría y un mensaje vía WhatsApp con la bienvenida a nuestro servicio.</p>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <img src="/images/a5.png" alt="¿Qué hacemos?"/>
                        </picture>
                        <div className="content">
                            <h3>¿Qué hacemos?</h3>
                            <p>Todo lo necesario para facilitar tu vida, tendrás tu correo personalizado para atender todos tus mensajes, calendarizar y confirmar reuniones. Diario al iniciar el día recibirás un correo y un nuestro recordandote tus actividades diarias, un por menor de las noticias más importantes en base a tus gustos y algunas recomendaciones. ¿Necesitas algo? Escibenos por cualquiera de los medios establecidos y te responderemos lo más rápido posible.</p>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <img src="/images/a8.png" alt="Personalización"/>
                        </picture>
                        <div className="content">
                            <h3>Personalización</h3>
                            <p>Utilizamos las mejores herramientas de tecnología y creamos flujos avanzados para poder atender todas tus solicitudes, crear informes personalizados y ayudarte ante cada situación, envíanos fotos de tus gastos y nosotros los controlamos y te recordamos si llegas a necesitar reclamar alguna garantía o servicio de mantenimiento, envíanos tu estado de cuenta bancario y nosotros lo conciliamos en tu cuenta, ¿Necesitas algún proveedor, alguna recomendación, alguna reservación o cualquier otra cosa? nosotros te ayudamos, te realizaremos recomendaciones en base a tu estilo de vida y te ayudaremos a aplicar controles y dar seguimiento.</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="process-resalted">
                <div className="process-resalted__container">
                    <h2>Comienza ahora, regístrate y obtén 10 días de servicio sin costo.</h2>
                    <Link to="/registro">Comenzar registro</Link>
                </div>
            </div>
        </div>
    )
}