import React, { useState, useEffect } from 'react';
import firebase from '../../utils/firebase';
import moment from 'moment';
import _ from 'lodash';

export default function AdminMessages () {

    const [messages,setmessages] = useState([])

    useEffect(() => {
        firebase.getCollection('messages').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setmessages(data)
        })
    },[])

    const complete = _.filter(messages, o => {
        return o.read === true
    });
    const pending = _.filter(messages, o => {
        return o.read !== true
    });

    return (
        <div className="admin-messages">
            <table style={{ marginRight: '2.5rem' }}>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Mensaje</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pending.map(item => <tr key={item.id}>
                            <td>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.message}</td>
                        </tr>)
                    }
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Mensaje</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        complete.map(item => <tr key={item.id}>
                            <td>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.message}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}