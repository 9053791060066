import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'

export default function Registry (props) {

    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [password,setpassword] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.isInitialized().then(user => {
            if (user) {
                props.history.push('/app')
            }
        })
    },[props.history])

    return (
        <div className="login">
            <div className="registry__container">
                <div className="content">
                    <h1>Regístrate en nuestra plataforma y obtén 10 días de prueba gratis. ¡Comienza ahora!</h1>
                    <p>Una vez que realizas tu registro un asesor se pondrá en contacto contigo para que conozcas todo lo que podemos hacer por ti. Básicamente todo lo que haga tu vida más fácil.</p>
                    <h2>Principales casos</h2>
                    <h3>- ¿Qué pasa cuando nos envías un comprobante de gasto?</h3>
                    <p>Nuestro equipo se encargará de solicitar la factura correspondiente con tus datos fiscales (en caso de requerirlo), ingresar tu gasto en tu reporte financiero, verificaremos si tu compra cuenta con garantía o póliza de mantenimiento para recordarte en caso de que sea necesario.</p>
                    <h3>- ¿Se te descompuso un equipo en tu hogar?</h3>
                    <p>Escribe a tu asistente personal, envía fotos y/o una descripción del problema, nosotros nos encargaremos de todo lo demás, realizar cotizaciones y seleccionar para ti las mejores opciones, nosotros nos encargamos de recordarte sobre las garantías y mantenimiento.</p>
                </div>
            </div>
            <form className="reg" onSubmit={e => e.preventDefault() && false}>
                <h1>Forma parte de Asistencia.Vip</h1>
                <div className="input-container">
                    <label>Nombre</label>
                    <input value={name} onChange={e => setname(e.target.value)} type="text" />
                </div>
                <div className="input-container">
                    <label>Email</label>
                    <input value={email} onChange={e => setemail(e.target.value)} type="email" />
                </div>
                <div className="input-container">
                    <label>Contraseña</label>
                    <input value={password} onChange={e => setpassword(e.target.value)} type="password" />
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Registrarme</button>
                </div>
            </form>
        </div>
    )

    async function handleSubmit () {
        try {
            if (name && email && password) {
                firebase.register(name,email,password).then(() => {
                    props.history.push('/app')
                }).catch(error => {
                    alert(error.message)
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}