import React, { useEffect } from 'react'

export default function Project () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="project">
            <div className="project__container">
                <h1>Proyecto</h1>
                <p>Concoe el proyecto Asistencia.Vip los pasos que seguiremos para mejorar el servicio, mejorar costos para nuestros clientes y aumentar la productividad.</p>
                <ul>
                    <li>
                        <span>1</span>
                        <div className="content">
                            <h3>Item de prueba 1</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                    </li>
                    <li>
                        <span>2</span>
                        <div className="content">
                            <h3>Item de prueba 2</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                    </li>
                    <li>
                        <span>3</span>
                        <div className="content">
                            <h3>Item de prueba 3</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                    </li>
                    <li>
                        <span>4</span>
                        <div className="content">
                            <h3>Item de prueba 4</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}