import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Link } from 'react-router-dom'

import ServiceItem from '../../components/ServiceItem'

export default function Home () {

    const [email,setemail] = useState('')
    const [services,setServices] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getCollection('services').orderBy('timestamp').limit(4).get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setServices(data)
        })
    },[])

    return (
        <div className="home">
            <div className="home__cover">
                <div className="home__cover-container">
                    <h1>Facilitando tu vida y asistiendo tus labores diarias</h1>
                </div>
            </div>
            <div className="home__cases">
                <div className="home__cases-container">
                    <h2>Principales Servicios</h2>
                    <ul>
                        {services.map(item => <ServiceItem key={item.id} data={item} />)}
                    </ul>
                    <Link to="/servicios">Ver todos los servicios incluidos</Link>
                </div>
            </div>
            <div className="home__separator">
                <div className="home__separator-container">
                    <h2>Asistencia.vip, acompañandote en cada paso de tu vida.</h2>
                    <Link to="/registro">Comenzar</Link>
                </div>
            </div>
            <div className="home__about">
                <div className="home__about-container">
                    <div className="content">
                        <h3>¿Cómo funciona?</h3>
                        <p>Regístrate y comienza a utilizar todos los servicios de Asistencia.vip, completa tu perfil y conoce todo lo que podemos hacer por ti. Al completar tu registro, un asesor se pondrá en contacto contigo para comenzar a trabajar juntos de inmediato. Muy fácil, tu asistente personal al mejor precio, con los mejores proveedores y listos para asistirte en cualquier momento.</p>
                        <p>Conoce todas las funciones de nuestra plataforma, conoce cómo funciona nuestro servicio y comienza con tus 10 días de prueba gratis. </p>
                        <Link to="/proceso">Conoce el proceso</Link>
                    </div>
                    <picture>
                        <img src="/images/a7.png" alt="Asistencia.vip"/>
                    </picture>
                </div>
            </div>
            <div className="home__contact">
                <div className="home__contact-container">
                    <h3>Recibe más información</h3>
                    <p>Escribe tu correo electrónico para recibir más información de nuestro servicio y asistencia de un ejecutivo.</p>
                    <form onSubmit={e => e.preventDefault()}>
                        <label>Correo Electrónico</label>
                        <input value={email} onChange={e => setemail(e.target.value)} placeholder="Correo Electrónico" type="email"/>
                        <button onClick={handleSuscribe} type="submit">Enviar solicitud de información</button>
                    </form>
                </div>
            </div>
        </div>
    )

    async function handleSuscribe () {
        try {
            if (email) {
                const item = {
                    email
                }
                firebase.suscribe(item).then(() => {
                    setemail('')
                    alert('Te has suscrito a nuestro boletín de noticias.')
                })
            } else {
                alert('Escribe tu email para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}