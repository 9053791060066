import { createMuiTheme } from '@material-ui/core/styles';
import { red, grey } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
	palette: {
        type: 'dark',
		primary: {
            main: '#EF0D33'
        },
		secondary: {
			light: grey[400],
			main: grey[700],
			dark: grey[900],
			contrastText: '#fff',
		},
		error: {
			main: red.A400,
		},
    },
    typography: {
		fontSize: 22,
		fontFamily: [
			'"Montserrat"',
			'sans-serif'
		].join(',')
    }
});

export default theme;