import React, { useState } from 'react'
import firebase from '../../utils/firebase'
import { useEffect } from 'react'

export default function Providers () {

    const [contactName,setcontactName] = useState('')
    const [enterpriseName,setenterpriseName] = useState('')
    const [services,setservices] = useState('')
    const [email,setemail] = useState('')
    const [description,setdescription] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="providers">
            <div className="providers__container">
                <h1>Proveedores</h1>
                <p>Forma parte de nuestra red de más de 500 proveedores bajo los más altos estándares de calidad, todos nuestros proveedores son investigados, creamos un expediente y entregamos una certificación como proveedor de calidad.</p>
                <form onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Nombre de Contacto</label>
                        <input placeholder="Nombre de Contacto" value={contactName} onChange={e => setcontactName(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Nombre de Empresa</label>
                        <input placeholder="Nombre de Empresa" value={enterpriseName} onChange={e => setenterpriseName(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Servicio(s), separados por coma</label>
                        <input placeholder="Servicio(s), separados por coma" value={services} onChange={e => setservices(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Correo Electrónico</label>
                        <input placeholder="Correo Electrónico" value={email} onChange={e => setemail(e.target.value)} type="email"/>
                    </div>
                    <div className="input-container">
                        <label>Descripción de empresa</label>
                        <input placeholder="Descripción de empresa" value={description} onChange={e => setdescription(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={handleSubmit} type="submit">Agregar</button>
                    </div>
                </form>
            </div>
        </div>
    )

    async function handleSubmit () {
        try {
            if (contactName && enterpriseName && services && email && description) {
                const item = {
                    contactName,
                    enterpriseName,
                    services,
                    email,
                    description,
                }
                firebase.sendMessage(item).then(() => {
                    setcontactName('')
                    setenterpriseName('')
                    setservices('')
                    setemail('')
                    setdescription('')
                    alert('Mensaje enviado un ejecutivo se pondrá en contacto contigo')
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}