import React from 'react'

export default function ServiceItem ({ data }) {

    return (
        <div className="service-item">
            <img src={data.image} alt={data.name}/>
            <div className="container">
                <h3>{data.name}</h3>
                <p>{data.description}</p>
            </div>
        </div>
    )
}