import React, { useEffect } from 'react'
import firebase from '../utils/firebase'
import { Link, Switch, Route } from 'react-router-dom'

import AdminDashboard from '../pages/admin/AdminDashboard'
import AdminServices from '../pages/admin/AdminServices'
import AdminUsers from '../pages/admin/AdminUsers'
import AdminSingleUser from '../pages/admin/AdminSingleUser'
import AdminMessages from '../pages/admin/AdminMessages'
import AdminSuscriptions from '../pages/admin/AdminSuscriptions'

export default function Admin (props) {

    const loc = props.location.pathname.split('/')[2]

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.getDocument('users',user.uid).get().then(snap => {
                    const data = snap.data()
                    if (data.rol !== 'admin') {
                        props.history.push('/app')
                    }
                })
            } else {
                props.history.push('/iniciar-sesion')
            }
        })
    },[props.history])

    return (
        <div className="admin">
            <div className="admin__sidenav">
                <ul>
                    <Link className={loc === undefined || loc === '' ? 'active' : ''} to="/admin">Dashboard</Link>
                    <Link className={loc === 'servicios' ? 'active' : ''} to="/admin/servicios">Servicios</Link>
                    <Link className={loc === 'usuarios' ? 'active' : ''} to="/admin/usuarios">Usuarios</Link>
                    <Link className={loc === 'mensajes' ? 'active' : ''} to="/admin/mensajes">Mensajes</Link>
                    <Link className={loc === 'suscripciones' ? 'active' : ''} to="/admin/suscripciones">Suscripciones</Link>
                    <Link className={loc === 'partners' ? 'active' : ''} to="/admin/partners">Partners</Link>
                    <Link className={loc === 'proveedores' ? 'active' : ''} to="/admin/proveedores">Proveedores</Link>
                    <Link className={loc === 'transacciones' ? 'active' : ''} to="/admin/transacciones">Transacciones</Link>
                    <Link to="/">Regresar a inicio</Link>
                </ul>
            </div>
            <div className="admin__container">
                <Switch>
                    <Route path="/admin" component={AdminDashboard} exact={true} />
                    <Route path="/admin/servicios" component={AdminServices} exact={true} />
                    <Route path="/admin/mensajes" component={AdminMessages} exact={true} />
                    <Route path="/admin/suscripciones" component={AdminSuscriptions} exact={true} />
                    <Route path="/admin/usuarios" component={AdminUsers} exact={true} />
                    <Route path="/admin/usuarios/:id" component={AdminSingleUser} exact={true} />
                </Switch>
            </div>
        </div>
    )
}