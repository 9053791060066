import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Container, List, ListItem, ListItemText, Typography, Divider, Grid, Paper, Box, TextField, Button } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'

export default function Comunication ({ user }) {

    const [description,setdescription] = useState('')
    const [tasks,settasks] = useState([])

    useEffect(() => {
        firebase.getCollection('tasks').where('userId','==',user.id).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            settasks(data)
        })
    },[user.id])

    const incomplete = _.filter(tasks, o => {
        return o.complete ===  false
    })
    const complete = _.filter(tasks, o => {
        return o.complete === true
    })

    return (
        <Container>
            <Typography>A continuación, te escribimos los medios por los cuales te contactaremos y nos puedes contactar para brindarte el mejor servicio de asistencia.</Typography>
            <List>
                <ListItem>
                    <ListItemText
                        primary="WhatsApp"
                        secondary="+52 55 1530 6640"
                    />
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemText
                        primary="Email"
                        secondary="contacto@asistencia.vip"
                    />
                </ListItem>
                <Divider/>
            </List>
            <Grid style={{ marginTop: '2rem' }} container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Box component={Paper} padding="2.5rem">
                        <Typography variant="h6" component="h2">Escribenos tareas, preguntas o cualqueir comentario que quieras hacer a tu asistente.</Typography>
                        <TextField
                            variant="outlined"
                            label="Descripción"
                            fullWidth
                            value={description}
                            onChange={e => setdescription(e.target.value)}
                            style={{ marginTop: '1rem' }}
                            multiline
                            rows="5"
                        />
                        <Button onClick={handleSubmit} style={{ marginTop: '1rem' }} fullWidth variant="contained" size="large" color="primary">Agregar</Button>
                    </Box>
                    <Typography style={{ marginBottom: '2rem', marginTop: '2rem' }} align="center" variant="h5" component="h2">Pendientes</Typography>
                    {
                        incomplete.map(item => <Box key={item.id} display="flex" flexDirection="column" marginBottom="2rem" component={Paper} width="100%" padding="2.5rem">
                            <Typography variant="body1">{item.description}</Typography>
                            <Typography variant="caption" align="right">{moment(item.timestamp).format('DD-MM-YYYY')}</Typography>
                        </Box>)
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography style={{ marginBottom: '2rem', marginTop: '2rem' }} align="center" variant="h5" component="h2">Atendidos</Typography>
                    {
                        complete.map(item => <Box key={item.id} display="flex" flexDirection="column" marginBottom="2rem" component={Paper} width="100%" padding="2.5rem">
                            <Typography variant="body1">{item.description}</Typography>
                            <Typography variant="caption" align="right">{moment(item.timestamp).format('DD-MM-YYYY')}</Typography>
                        </Box>)
                    }
                </Grid>
            </Grid>
        </Container>
    )

    async function handleSubmit() {
        try {
            if (description) {
                const item = {
                    description,
                    complete: false
                }
                firebase.simpleAdd(item,'tasks').then(() => {
                    setdescription('')
                    alert('Elemento agregado')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}