import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'

const Item = ({ data }) => {

    const [image,setImage] = useState('')

    return (
        <li>
            <div className="input-container">
                <label>Nombre</label>
                <input defaultValue={data.name} onChange={e => firebase.updateDoc('services',data.id,'name',e.target.value)} type="text"/>
            </div>
            <div className="input-container">
                <label>¿Activo?</label>
                <select defaultValue={data.isActive} onChange={e => firebase.updateDoc('services',data.id,'isActive',e.target.value)}>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                </select>
            </div>
            <div className="input-container">
                <label>Descripción</label>
                <textarea defaultValue={data.description} onChange={e => firebase.updateDoc('services',data.id,'description',e.target.value)} rows="2" />
            </div>
            <hr/>
            {data.image ? <picture style={{ width: '100%' }}>
                <img style={{ width: '100%' }} src={data.image} alt={data.name}/>
            </picture> : ''}
            <div className="input-container">
                <label>Imagen (1x1)</label>
                <input onChange={e => setImage(e.target.files[0])} type="file"/>
                <button onClick={handleImage}>Establecer imagen</button>
            </div>
        </li>
    )

    async function handleImage () {
        try {
            if (image) {
                firebase.addFileDoc('services',data.id,'image',image).then(() => {
                    alert('Imagen actualizada')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}

export default function AdminServices () {

    const [services,setServices] = useState([])
    const [name,setname] = useState('')

    useEffect(() => {
        firebase.getCollection('services').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setServices(data)
        })
    },[])

    return (
        <div className="admin-services">
            <form onSubmit={e => e.preventDefault() && false}>
                <div className="input-container">
                    <label>Nombre de Servicio</label>
                    <input placeholder="Nombre de Servicio" value={name} onChange={e => setname(e.target.value)} type="text"/>
                </div>
                <div className="input-container">
                    <button onClick={handleSubmit} type="submit">Agregar</button>
                </div>
            </form>
            <ul>
                {services.map(item => <Item key={item.id} data={item} />)}
            </ul>
        </div>
    )

    async function handleSubmit() {
        try {
            if (name) {
                const item = {
                    name,
                    isActive: 'false'
                }
                firebase.simpleAdd(item,'services').then(() => {
                    setname('')
                })
            } else {
                alert('Escribe un nombre para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}