import React, { useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import firebase from '../utils/firebase'
import moment from 'moment'

import Home from '../pages/client/Home'
import Services from '../pages/client/Services'
import Login from '../pages/Login';
import Registry from '../pages/Registry';
import Process from '../pages/client/Process'
import Providers from '../pages/client/Providers'
import Contact from '../pages/client/Contact'
import Project from '../pages/client/Project'
import Priv from '../pages/client/Priv'

export default function Client () {

    const [email,setemail] = useState(undefined)

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                setemail(user.email)
            }
        })
    },[])

    return (
        <div className="client">
            <header>
                <div className="header__container">
                    <Link style={{ backgroundColor: 'transparent' }} to="/" className="logo">
                        <img src="/images/logo.png" alt="Asistencia VIP"/>
                    </Link>
                    <ul>
                        <Link to="/"><span>Inicio</span></Link>
                        <Link to="/proceso"><span>Proceso</span></Link>
                        <Link to="/servicios"><span>Servicios</span></Link>
                        <Link to="/proveedores"><span>Proveedores</span></Link>
                    </ul>
                    {!email ? <Link to="/iniciar-sesion">Iniciar Sesión</Link> : <Link to="/app">{email}</Link>}
                    {!email ? <Link to="/registro">Registro</Link> : ''}
                </div>
            </header>
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/proceso" component={Process} exact={true} />
                <Route path="/servicios" component={Services} exact={true} />
                <Route path="/proveedores" component={Providers} exact={true} />
                <Route path="/proyecto" component={Project} exact={true} />
                <Route path="/contacto" component={Contact} exact={true} />
                <Route path="/aviso-privacidad" component={Priv} exact={true} />
                <Route path="/iniciar-sesion" component={Login} exact={true} />
                <Route path="/registro" component={Registry} exact={true} />
            </Switch>
            <footer>
                <div className="footer__container">
                    <div className="footer__top">
                        <ul>
                            <Link to="/">Inicios</Link>
                            <Link to="/proceso">Proceso</Link>
                            <Link to="/servicios">Servicios</Link>
                            <Link to="/proveedores">Proveedores</Link>
                        </ul>
                        <ul>
                            {/* <Link to="/proyecto">Proyecto</Link> */}
                            <Link to="/contacto">Contacto</Link>
                            {/* <Link to="/terminos-condiciones">Terminos y Condiciones</Link> */}
                            <Link to="/aviso-privacidad">Aviso de Privacidad</Link>
                        </ul>
                    </div>
                    <div className="footer__bottom">
                        <p>&copy; {moment().format('YYYY')}. Todos los derechos reservados. Hecho por <a target="_blank" rel="noopener noreferrer" href="https://thebusinessyoung.com">TBY</a></p>
                        <Link to="/aviso-privacidad">Aviso de Privacidad</Link>
                    </div>
                </div>
            </footer>
        </div>
    )
}